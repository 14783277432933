import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BanklinkResponse } from '@shared/models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoadPaymentsFail, LoadPaymentsSuccess, PaymentActionTypes } from '../actions/payment.actions';

@Injectable()
export class PaymentEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActionTypes.LOAD_PAYMENTS),
      switchMap(() =>
        this.db
          .doc<BanklinkResponse>('payment/links')
          .valueChanges()
          .pipe(
            map((change) => new LoadPaymentsSuccess(change as BanklinkResponse)),
            catchError((error) => of(new LoadPaymentsFail(error)))
          )
      )
    );
  });

  constructor(private actions$: Actions, private db: AngularFirestore) {}
}
