import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { slugify } from './slugify';
import { takeValue } from './util';

export interface IProduct {
  title?: string;
  image?: string | null;
  thumb?: string | null;
  imageOriginal?: string | null;
  id?: string;
  slug?: string;
  shortDescription?: string;
  description?: string;
  size?: number;
  price?: number;
  active?: boolean;
  inventory?: number;
}

@Injectable()
export class ProductService {
  inStock: Observable<IProduct[] | undefined>;
  all: Observable<IProduct[] | undefined>;
  slugs: Observable<Set<string>>;
  itemsMap: Observable<{ [id: string]: IProduct } | undefined | null>;

  constructor(private db: AngularFirestore, private auth: AuthService) {
    // Observable for in stock items
    const inStockSubject = new BehaviorSubject<IProduct[] | undefined>(undefined);
    this.inStock = inStockSubject.asObservable();
    this.db
      .collection<IProduct>('products', (ref) => ref.where('active', '==', true).where('inventory', '>', 0))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes
            .map((change) => ({
              ...change.payload.doc.data(),
              id: change.payload.doc.id,
            }))
            .map((item) => this.createProduct(item))
        )
      )
      .subscribe(
        (products) => inStockSubject.next(products),
        (e) => console.log(e)
      );

    this.itemsMap = this.inStock.pipe(
      map((products) => {
        if (products === undefined) return undefined;
        if (products === null) return null;
        return products.reduce(
          (prev, current) => {
            if (current.id === undefined) return prev;
            return {
              ...prev,
              [current.id]: current,
            };
          },
          {} as {
            [id: string]: IProduct;
          }
        );
      })
    );

    // Observable for all items
    const allSubject = new BehaviorSubject<IProduct[] | undefined>(undefined);
    this.all = allSubject.asObservable();
    this.auth.roles
      .pipe(
        switchMap((roles) =>
          roles && roles.admin ? this.db.collection<IProduct>('products').snapshotChanges() : EMPTY
        ),
        tap((d) => console.log(d)),
        map((changes) =>
          changes.map((change) => ({
            ...change.payload.doc.data(),
            id: change.payload.doc.id,
          }))
        )
      )
      .subscribe(
        (products) => allSubject.next(products),
        (e) => console.log(e)
      );

    this.slugs = this.all.pipe(
      map((all) =>
        (all ?? []).reduce((set, product) => {
          if (!product.slug) return set;
          return set.add(product.slug);
        }, new Set<string>())
      ),
      tap((d) => console.log(d))
    );
  }

  add(product: IProduct) {
    const base: IProduct = {
      inventory: 0,
      active: true,
    };
    if (!product.slug && product.title) {
      base.slug = this.generateSlug(product.title);
    }
    console.log(base);
    this.db.collection('products').add({ ...base, ...product });
  }

  update(product: IProduct) {
    const { id, ...params } = product;
    console.log(product);
    this.db.doc(`products/${id}`).update(params);
  }

  generateSlug(title: string) {
    const slug = slugify(title);
    const slugSet = takeValue(this.slugs);
    if (!slugSet.has(slug)) return slug;
    let i = 1;
    while (slugSet.has(slug + '-' + i)) i++;
    return slug + '-' + i;
  }

  findBySlug(slug?: string | null, all: boolean = false) {
    return this.find(slug as string, 'slug', all ? this.all : this.inStock);
  }

  findById(id: string) {
    return this.find(id, 'id', this.inStock);
  }

  private createProduct(data: any) {
    console.log('8603', data);
    const product: IProduct = { ...data, image: null, thumb: null };
    if (data.image) {
      product.imageOriginal = data.image;
      product.image = environment.productImageRoot + '/' + data.image.split('/').pop();
      product.thumb = product.image + '?twic=v1/quality=90/resize=150';
    }
    console.log('9477', product);
    return product;
  }

  // getThumbUrl(imageUrl: string | undefined) {
  //   if (!imageUrl) {
  //     captureException(new Error('Unable to create product image thumb URL'));
  //     return null;
  //   }
  //   const urlArray = imageUrl.split('/');
  //   const [file, extension] = (urlArray.pop() as string).split('.');
  //   const base = urlArray.join('/');
  //   const newFile = [file, 'min', extension].filter(Boolean).join('.');
  //   return [base, 'thumb', newFile].join('/');
  // }

  private find(id: string, searchBy: string, observable: Observable<IProduct[] | undefined>) {
    return observable.pipe(
      map((products) => {
        if (products === undefined) return undefined;
        const product = products.find((item) => item[searchBy as keyof IProduct] === id);
        return product === undefined ? null : product;
      })
    );
  }
}
