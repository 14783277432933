import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AnalyticsService } from './shared/analytics/analytics.service';
import { User, UserRoles } from './shared/auth.service';
import { Cart } from './shared/cart.service';
import { MetadataService } from './shared/metadata.service';

@Component({
  selector: 'mp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Meepood - avaleht';
  cart!: Observable<Cart>;
  user!: Observable<User>;
  roles!: Observable<UserRoles>;

  constructor(
    private icons: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private meta: MetadataService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.analytics.appendTrackingCode();
    this.meta.initialize();
    this.icons.addSvgIcon(
      'logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('../assets/logo.svg')
    );
    this.icons.addSvgIcon(
      'flogo',
      this.sanitizer.bypassSecurityTrustResourceUrl('../assets/flogo.svg')
    );
  }
}
