import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { IProduct, ProductService } from './product.service';
import { takeValue } from './util';

export interface CartMap {
  [id: string]: number;
}

export interface Cart {
  items: CartMap;
  modified: Date | null;
}

export interface CartProduct extends IProduct {
  count: number;
}

export interface CartCost {
  transport: number;
  items: number;
  total: number;
}

@Injectable()
export class CartService implements OnDestroy {
  private costSubject = new BehaviorSubject<CartCost>({} as CartCost);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  cart: Observable<Cart | undefined>;
  cartItems: Observable<CartProduct[] | undefined | null>;
  cost = this.costSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();
  private cartDoc: Observable<AngularFirestoreDocument<Cart> | null>;
  private destroy$ = new Subject();

  constructor(private db: AngularFirestore, private auth: AuthService, private productService: ProductService) {
    this.cartDoc = this.auth.uid.pipe(
      switchMap((uid) => {
        if (!uid) return of(null);
        return of(this.db.doc<Cart>(`carts/${uid}`));
      })
    );

    const cartSubject = new BehaviorSubject<Cart | undefined>(undefined);
    this.cart = cartSubject.asObservable();

    this.cartDoc
      .pipe(
        switchMap((doc) =>
          doc ? doc.valueChanges().pipe(tap((e) => console.log('5294', e))) : of(null as unknown as Cart)
        ),
        map((cart) => this.cartFactory(cart)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (cart) => cartSubject.next(cart),
        (e) => console.log(e)
      );

    this.cartItems = combineLatest([
      this.cart.pipe(tap((e) => console.log('2764', e))),
      this.productService.itemsMap,
    ]).pipe(
      debounceTime(0),
      map(([cart, items]) => {
        console.log('9323', cart, items);
        if (cart === undefined && items === undefined) return undefined;
        if (!cart) return null;
        const cartIds = Object.keys(cart.items);
        if (!cartIds.length || !items) return null;
        const cartItems: CartProduct[] = cartIds.map((id) => ({
          ...items[id],
          id,
          count: cart.items[id],
        }));
        return cartItems;
      })
    );

    this.cartItems
      .pipe(
        filter((cartItems) => !!cartItems),
        withLatestFrom(this.cost),
        takeUntil(this.destroy$)
      )
      .subscribe(([cartItems, cost]) => {
        const itemsCost = (cartItems as CartProduct[])
          .filter((item) => item.price !== undefined)
          .reduce((prev, item) => prev + item.count * (item.price ?? 0), 0);
        const transportCost = (cost && cost.transport) || 0;
        const total = itemsCost + transportCost;

        this.costSubject.next({ ...cost, items: itemsCost, total });
      });
  }

  setLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  setTransportCost(transportCost: number) {
    const cost = takeValue(this.cost);
    const itemsCost = (cost && cost.items) || 0;
    const total = itemsCost + transportCost;
    this.costSubject.next({ ...cost, transport: transportCost, total });
  }

  addItem(item: IProduct) {
    return this.cartDoc.pipe(take(1), withLatestFrom(this.cart)).subscribe(([doc, cart]) => {
      if (!doc) throw Error('cart doc needs to be defined');
      const items = { ...(cart?.items ?? {}) };
      if (!item.id) throw Error('item id cannot be undefined');
      items[item.id] === undefined ? (items[item.id] = 1) : items[item.id]++;
      // TODO: should use firebase datetime token?
      const updatedCart = { ...cart, items, modified: new Date() };
      return doc.set(updatedCart);
    });
  }

  removeItem(id: string) {
    return this.cartDoc.pipe(take(1), withLatestFrom(this.cart)).subscribe(([doc, cart]) => {
      if (!doc) throw Error('cart doc needs to be defined');
      const items = { ...(cart?.items ?? {}) };
      delete items[id];
      // TODO: should use firebase datetime token?
      const updatedCart = { ...cart, items, modified: new Date() };
      doc.set(updatedCart);
    });
  }

  setItemQuantity(item: IProduct, quantity: number) {
    EMPTY.pipe(withLatestFrom())
    return this.cartDoc.pipe(take(1), withLatestFrom(this.cart)).subscribe(([doc, cart]) => {
      if (!doc) throw Error('cart doc needs to be defined');
      const items = { ...(cart?.items ?? {}) };
      if (!item.id) throw Error('item id cannot be undefined');
      items[item.id] = quantity;
      // TODO: should use firebase datetime token?
      const updatedCart = { ...cart, items, modified: new Date() };
      return doc.set(updatedCart);
    });
  }

  private cartFactory(cart: any): Cart {
    if (!cart) return cart;
    const defaultCart: Cart = {
      items: {},
      modified: null,
    };
    const modified = cart.modified ? cart.modified.toDate() : null;
    return { ...defaultCart, ...cart, modified };
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
