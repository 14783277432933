import { Action, ActionReducerMap, MetaReducer, Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { IPaymentState, paymentReducer } from './payment.reducer';

export interface IState {
  payment: IPaymentState;
}

export const reducers: ActionReducerMap<IState, Action> = {
  payment: paymentReducer as any,
};

export const metaReducers: MetaReducer<IState>[] = !environment.production
  ? []
  : [];

export type IAppStore = Store<IState>;
