import { Component, Input } from '@angular/core';
import { CartProduct } from '../cart.service';

@Component({
  selector: 'mp-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
})
export class CartIconComponent {
  @Input()
  set cartItems(items: CartProduct[] | null | undefined) {
    if (!items) {
      this.items = 0;
      return;
    }
    this.items = items.reduce((sum, item) => sum + item.count, 0);
  }

  items = 0;
}
