import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InputDialogData {
  title: string;
  content?: string;
  inputPlaceholder?: string;
  actionDiscard?: string;
  actionSuccess?: string;
}

@Component({
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      {{ data.content }}
      <mat-form-field>
        <input #input matInput [placeholder]="data.inputPlaceholder!" />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>
        {{ data.actionDiscard || 'Tühista' }}
      </button>
      <button
        mat-stroked-button
        [mat-dialog-close]="input.value"
        color="primary"
        cdkFocusInitial
      >
        {{ data.actionSuccess || 'OK' }}
      </button>
    </mat-dialog-actions>
  `,
})
export class InputDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: InputDialogData) {
    console.log(data);
  }
}
