<div class="item-list__container">
  <ng-container *ngIf="items | async as items">
    <mp-item-preview
      class="item-preview"
      *ngFor="let item of items"
      [item]="item"
    ></mp-item-preview>
    <h2 *ngIf="items?.length === 0" class="item-list__empty">
      Mee ostmiseks palun kirjuta
      <a href="mailto:info@meepood.ee">info@meepood.ee</a>
    </h2>
  </ng-container>
</div>
