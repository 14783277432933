import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad {
  constructor(private auth: AuthService, private router: Router) {}
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.roles.pipe(
      tap((roles) => console.log(roles)),
      filter((roles) => roles !== undefined),
      take(1),
      map((roles) => roles?.admin ?? false),
      tap((admin) => {
        if (!admin) {
          this.router.navigate(['/avaleht']);
        }
      })
    );
  }
}
