<mat-card class="item-preview__card">
  <mat-card-header>
    <mat-card-title>
      <h2 class="mat-subheading-2">{{ item.title }}</h2>
    </mat-card-title>
  </mat-card-header>
  <div [routerLink]="['/toode', item.slug]" class="item-preview__image__container" tabindex="-1">
    <img
      mat-card-image
      class="item-preview__image"
      [src]="item.image + '?twic=v1/quality=90/resize=260.5' || '/assets/placeholder.min.svg'"
      [alt]="item.title"
    />
  </div>

  <mat-card-content>
    <p>{{ item.shortDescription }}</p>
    <span class="space"></span>
    <span *ngIf="item.size"> <strong>Kogus: </strong>{{ item.size | weight }} </span>
    <span> <strong>Hind: </strong>{{ item.price | currency }} </span>
  </mat-card-content>

  <mat-card-actions class="item-preview__actions">
    <button mat-stroked-button [routerLink]="['/toode', item.slug]">Vaata lähemalt</button>
    <span class="space"></span>
    <div class="item-detail__product-quantity">
      <mp-product-quantity [cartItem]="cartItem | async"></mp-product-quantity>
    </div>
  </mat-card-actions>
</mat-card>
