import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env';
import * as Sentry from '@sentry/browser';

let sentryInit = false;

if (environment.production) {
  console.log = () => {};
}

if (environment.sentry) {
  Sentry.init({
    dsn: environment.sentry,
  });
  sentryInit = true;
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any) {
    if (sentryInit) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }
}

export function captureException(error: any) {
  if (sentryInit) {
    Sentry.captureException(error);
  } else {
    console.error(error);
  }
}

export const sentryProvider = environment.sentry
  ? [{ provide: ErrorHandler, useClass: SentryErrorHandler }]
  : [];
