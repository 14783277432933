import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ItemListComponent } from './item-list/item-list.component';
import { ItemPreviewComponent } from './item-preview/item-preview.component';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [ItemListComponent, ItemPreviewComponent, HomeComponent]
})
export class HomeModule {}
