import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Timestamp } from './models';

export interface LegalDocument {
  id: string;
  name: string;
  content: string;
  modified: Timestamp;
}

@Injectable({
  providedIn: 'root',
})
export class LegalService {
  documents!: LegalDocument[];
  constructor(private db: AngularFirestore) {}

  getDocuments() {
    if (this.documents) return of(this.documents);
    return this.db
      .collection<Omit<LegalDocument, 'id'>>('legal')
      .get()
      .pipe(
        map((data) => data.docs.map((doc) => ({ id: doc.id, ...doc.data() }))),
        tap((documents) => (this.documents = documents))
      );
  }

  setDocument(document: LegalDocument) {
    const { id, ...data } = document;
    return this.db.doc('legal/' + id).set({ ...data, modified: new Date() });
  }
}
