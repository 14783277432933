import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartCost, CartProduct } from '../../shared/cart.service';

@Component({
  selector: 'mp-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {
  @Input() cartItems!: CartProduct[];
  @Input() cartCost!: CartCost | null;
  @Input() static = false;

  @Output() removeItem = new EventEmitter<string>();
}
