import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToolbarService } from '../shared/toolbar/toolbar.service';
import { CONST } from '../shared/const';

@Component({
  selector: 'mp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  size = CONST.BREAKPOINTS;
  constructor(private toolbarService: ToolbarService) {}

  ngOnInit() {
    this.toolbarService.setFixed(true);
  }
  ngOnDestroy(): void {
    this.toolbarService.setFixed(false);
  }
}
