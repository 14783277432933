import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * DO NOT USE WITH ASYNC OBSERVABLES!
 * Returns the latest value from observable.
 *
 * @param observable sync observable from which to return the latest value from
 * @returns Latest value from observable
 */
export function takeValue<T>(observable: Observable<T>) {
  let value!: T;
  observable.pipe(take(1)).subscribe((val) => (value = val));
  return value;
}
