import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartProduct } from '@shared/cart.service';

@Component({
  selector: 'mp-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent {
  @Input() cartItem?: CartProduct;
  @Input() static = false;

  @Output() removeItem = new EventEmitter<string>();
}
