import { Component, Input } from '@angular/core';
import { AuthService, User, UserRoles } from '../auth.service';

@Component({
  selector: 'mp-auth-button',
  templateUrl: './auth-button.component.html',
  styleUrls: ['./auth-button.component.scss'],
})
export class AuthButtonComponent {
  @Input()
  user: User | undefined | null;
  @Input()
  roles: UserRoles | undefined | null;

  constructor(public authService: AuthService) {}
}
