import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartItemComponent } from '../cart/cart-item/cart-item.component';
import { CartComponent } from '../cart/cart/cart.component';
import { AuthButtonComponent } from './auth-button/auth-button.component';
import { CartIconComponent } from './cart-icon/cart-icon.component';
import { DialogService } from './dialog.service';
import { ConfirmDialogComponent } from './dialogs/confirm.component';
import { InfoDialogComponent } from './dialogs/info.component';
import { InputDialogComponent } from './dialogs/input.component';
import { FooterComponent } from './footer/footer.component';
import { RequestInterceptor } from './http-error-interceptor.service';
import { ShopLayoutComponent } from './layouts/shop-layout/shop-layout.component';
import { PrivacyComponent } from './legal/privacy.component';
import { TermsComponent } from './legal/terms.component';
import { MaterialModule } from './material.module';
import { JoinPipe } from './pipes/join.pipe';
import { WeightPipe } from './pipes/weight.pipe';
import { ProductQuantityComponent } from './product-quantity/product-quantity.component';
import { QuantityInputComponent } from './quantity-input/quantity-input.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

const exportedModules = [ReactiveFormsModule, MaterialModule, CommonModule];
const exportedComponents = [
  CartIconComponent,
  AuthButtonComponent,
  ToolbarComponent,
  SpinnerComponent,
  FooterComponent,
  JoinPipe,
  CartComponent,
  WeightPipe,
  QuantityInputComponent,
  ProductQuantityComponent,
];

@NgModule({
  imports: [RouterModule, ...exportedModules],
  exports: [...exportedModules, ...exportedComponents],
  declarations: [
    ...exportedComponents,
    InfoDialogComponent,
    InputDialogComponent,
    ConfirmDialogComponent,
    ShopLayoutComponent,
    PrivacyComponent,
    TermsComponent,
    CartItemComponent,
  ],
  entryComponents: [InfoDialogComponent, InputDialogComponent],
  providers: [DialogService, { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true }],
})
export class SharedModule {}
