import { Component, Input } from '@angular/core';
import { User, UserRoles } from '../auth.service';
import { CartProduct } from '../cart.service';

@Component({
  selector: 'mp-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input()
  user: User | undefined | null;
  @Input()
  roles: UserRoles | undefined | null;
  @Input()
  cartItems: CartProduct[] | undefined | null;
}
