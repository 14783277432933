/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mp-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => QuantityInputComponent), multi: true }],
})
export class QuantityInputComponent implements ControlValueAccessor {
  @Input() min?: number;
  @Input() max?: number;

  quantity?: number;
  isDisabled = false;

  disableIncrement = false;
  disableDecrement = false;

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = (_: any) => {
      this.quantity = _;
      fn(_);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any) {
    if (this.min !== undefined) {
      this.disableDecrement = value <= this.min;
      if (value < this.min) return;
    }
    if (this.max !== undefined) {
      this.disableIncrement = value >= this.max;
      if (value > this.max) return;
    }
    this.quantity = value;
    this.onChange(value);
  }

  increment(amount: number) {
    this.writeValue((this.quantity ?? 0) + amount);
  }
}
