import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env';
import { delay, filter } from 'rxjs/operators';

declare const gtag: (
  type: 'config',
  id: string,
  options: { page_path?: string }
) => void;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private id: string;

  constructor(@Inject(DOCUMENT) private doc: Document, private router: Router) {
    this.id = environment.analytics.trackingId;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        delay(0)
      )
      .subscribe((event) => {
        (window as any).dataLayer.push([
          'config',
          environment.analytics.trackingId,
        ]);
        gtag('config', this.id, {
          page_path: (event as NavigationEnd).urlAfterRedirects,
        });
      });
  }

  appendTrackingCode() {
    try {
      const gtag = this.doc.createElement('script');
      gtag.setAttribute('type', 'text/javascript');
      gtag.setAttribute('async', '');
      gtag.setAttribute(
        'src',
        `https://www.googletagmanager.com/gtag/js?id=${this.id}`
      );
      const init = this.doc.createElement('script');
      init.setAttribute('type', 'text/javascript');
      init.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${this.id}', { 'send_page_view': false });`;
      this.doc.head.append(gtag, init);
    } catch (ex) {
      // TODO: Send to logger
      console.error('Error initializing Google Analytics');
      console.error(ex);
    }
  }
}
