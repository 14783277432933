<button
  mat-button
  routerLink="/tuvastus"
  *ngIf="!user || user.isAnonymous; else menu"
>
  <mat-icon>person</mat-icon>Logi sisse
</button>
<ng-template #menu>
  <button mat-button [matMenuTriggerFor]="userMenu">
    <span *ngIf="user?.fullName">{{ user!.fullName }}</span>
    <mat-icon *ngIf="!user?.fullName">person</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu">
    <button mat-menu-item routerLink="/minu/andmed">Minu andmed</button>
    <button mat-menu-item routerLink="/minu/tellimused">Minu tellimused</button>
    <button mat-menu-item *ngIf="roles?.admin" routerLink="/admin">
      Administreerimine
    </button>
    <button mat-menu-item (click)="authService.logout()">Logi välja</button>
  </mat-menu>
</ng-template>
