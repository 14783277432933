<button mat-raised-button (click)="increment(-1)" [disabled]="disableDecrement || isDisabled">
  <mat-icon>remove</mat-icon>
</button>
<!-- <button mat-icon-button (click)="increment(-1)" [disabled]="disableDecrement || isDisabled">
  <mat-icon>remove_circle_outline</mat-icon>
</button> -->
<span class="quantity-input__quantity">{{ quantity }}</span>
<!-- <button mat-icon-button (click)="increment(1)" [disabled]="disableIncrement || isDisabled">
  <mat-icon>add_circle_outline</mat-icon>
</button> -->
<button mat-raised-button (click)="increment(1)" [disabled]="disableIncrement || isDisabled">
  <mat-icon>add</mat-icon>
</button>
