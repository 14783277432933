import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CartProduct, CartService } from '@shared/cart.service';
import { CONST } from '@shared/const';
import { DialogService } from '@shared/dialog.service';
import { IProduct } from '@shared/product.service';
import { EMPTY, Subject, timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-product-quantity',
  templateUrl: './product-quantity.component.html',
  styleUrls: ['./product-quantity.component.scss'],
})
export class ProductQuantityComponent implements OnChanges, OnInit, OnDestroy {
  @Input() cartItem?: CartProduct | null;
  @Input() min?: number;

  quantityControl = new FormControl();

  private destroy$ = new Subject();

  constructor(private cartService: CartService, private dialog: DialogService) {}

  ngOnInit() {
    this.quantityControl.valueChanges
      .pipe(
        debounce((value) => (value > 0 ? timer(3 * CONST.DEBOUNCE_TIME) : EMPTY)),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        if (value === 0) {
          const dialogRef = this.dialog.openConfirm({
            title: `Eemalda ${this.cartItem?.title ?? 'toode'}?`,
            content: `Kas oled kindel, et soovid toote otsukorvist eemaldada?`,
          });
          dialogRef.afterClosed().subscribe((answer) => {
            if (answer) {
              this.cartService.removeItem(this.cartItem?.id as string);
            } else {
              this.quantityControl.setValue(1);
            }
          });
        } else {
          this.cartService.setItemQuantity(this.cartItem as IProduct, value);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleCartItemChange(changes.cartItem);
  }

  addItem(item: CartProduct) {
    this.cartService.addItem(item);
  }

  private handleCartItemChange(change: SimpleChange) {
    if (!change) return;
    const currentValue: CartProduct = change.currentValue;
    if (this.quantityControl.value !== currentValue.count) {
      this.quantityControl.setValue(currentValue.count);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    (this.destroy$ as any) = undefined;
  }
}
