import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SuccessDialogData {
  title: string;
  content: string;
}

@Component({
  template: `
    <h2 mat-dialog-title>
      <mat-icon color="primary" style="height: 28px; vertical-align: middle;">check_circle</mat-icon> {{ data.title }}
    </h2>
    <mat-dialog-content>
      <span>{{ data.content }}</span>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>OK</button>
    </mat-dialog-actions>
  `,
})
export class SuccessDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: SuccessDialogData) {}
}
