import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from './dialogs/confirm.component';
import { InfoDialogComponent, InfoDialogData } from './dialogs/info.component';
import { InputDialogComponent, InputDialogData } from './dialogs/input.component';
import { SuccessDialogComponent } from './dialogs/success.component';

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog) {}

  openInfo(title: string, content: string, config?: MatDialogConfig) {
    config = { ...config, data: { title, content } };
    return this.dialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, config);
  }

  openSuccess(title: string, content: string, config?: MatDialogConfig) {
    config = { ...config, data: { title, content } };
    return this.dialog.open<SuccessDialogComponent, InfoDialogData>(SuccessDialogComponent, config);
  }

  openInput(data: InputDialogData) {
    return this.dialog.open<InputDialogComponent, InputDialogData>(InputDialogComponent, { data });
  }

  openConfirm(data: ConfirmDialogData) {
    return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, { data });
  }
}
