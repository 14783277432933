import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AdminGuard } from './shared/guards/admin.guard';
import { ShopLayoutComponent } from './shared/layouts/shop-layout/shop-layout.component';
import { PrivacyComponent } from './shared/legal/privacy.component';
import { TermsComponent } from './shared/legal/terms.component';

const routes: Routes = [
  {
    path: '',
    component: ShopLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'avaleht' },
      { path: 'avaleht', component: HomeComponent, data: { title: 'Meepood - avaleht' } },
      { path: 'toode', loadChildren: () => import('./item-detail/item-detail.module').then(m => m.ItemDetailModule), data: { title: 'Meepood' } },
      { path: 'ostukorv', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule) },
      { path: 'tuvastus', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
      { path: 'minu', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
      { path: 'privaatsus', component: PrivacyComponent, data: { title: 'Meepood - privaatsustingimused' } },
      { path: 'tingimused', component: TermsComponent, data: { title: 'Meepood - müügitingimused' } }
    ]
  },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canLoad: [AdminGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
