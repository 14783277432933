<ng-container *ngIf="cartItem?.active === false; else productTemplate">
  <div class="cart__item__content__deleted">
    <span>Toode on vahepeal otsa saanud :(</span>
    <span class="space"></span>
    <button *ngIf="!static" mat-icon-button (click)="removeItem.emit(cartItem!.id)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-container>
<ng-template #productTemplate>
  <div class="cart__item__content">
    <div>
      <img class="cart__item-image" [src]="cartItem!.thumb || cartItem!.image || '/assets/placeholder.min.svg'" />
    </div>
    <div class="cart__details">
      <div class="cart__details-header">
        <h3>{{ cartItem!.title }}</h3>
        <span class="space"></span>
        <button *ngIf="!static" mat-icon-button (click)="removeItem.emit(cartItem!.id)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="cart_item__description-container">
        <p class="cart_item__description">
          {{ cartItem?.shortDescription }}
        </p>
        <span class="space"></span>
      </div>
      <div class="cart__price">
        <h4>Hind: {{ cartItem!.price ?? 0 | currency }}</h4>
        <ng-container *ngIf="!static; else staticQuantity">
          <mp-product-quantity [cartItem]="cartItem"></mp-product-quantity>
        </ng-container>
        <ng-template #staticQuantity>Kogus: {{ cartItem!.count }} tk</ng-template>
      </div>
    </div>
  </div>
</ng-template>
<mat-divider></mat-divider>
