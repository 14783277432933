<header>
  <mat-toolbar class="toolbar" color="primary">
    <div class="logo_container" routerLink="/">
      <img class="logo" src="assets/logo-light.min.svg" />
      <h1>Meepood</h1>
    </div>
    <!-- <mat-icon routerLink="/" id="logo" svgIcon="logo"></mat-icon> -->
    <span class="space"></span>
    <nav>
      <mp-cart-icon [cartItems]="cartItems"></mp-cart-icon>
      <mp-auth-button [user]="user" [roles]="roles"></mp-auth-button>
    </nav>
  </mat-toolbar>
</header>
