export const environment = {
  production: true,
  debug: false,
  apiRoot: 'api',
  info: {
    companyName: 'OÜ Vaha ja mesi',
    address: 'Mõisaküla tee 21, Saku vald, Harjumaa',
    regNumber: 12916678,
    contactPhone: 5555555,
    contactEmail: 'dev.meepood@dev.ee',
  },
  sentry: 'https://a4051c34d21145deaa8778c4c8fe8cce@sentry.io/1463863',
  productImageRoot: 'http://4kie15rj.twic.pics/staging/product/images',
  firebase: {
    name: 'ee-meepood-staging',
    functions: { domain: 'https://europe-west3-ee-meepood-staging.cloudfunctions.net' },
    options: {
      appId: '1:154893124792:web:8143b00bbe62e2b7c8604d',
      apiKey: 'AIzaSyD7wtOo6usewl8Tr8lUwdk3zuV9vx1IK-Q',
      authDomain: 'ee-meepood-staging.firebaseapp.com',
      databaseURL: 'https://ee-meepood-staging.firebaseio.com',
      projectId: 'ee-meepood-staging',
      storageBucket: 'ee-meepood-staging.appspot.com',
      messagingSenderId: '154893124792',
    },
  },
  analytics: { trackingId: 'UA-82104209-2' },
};
