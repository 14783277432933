import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private snackBar: MatSnackBar) {}
  handleError(error: Error) {
    if (error instanceof HttpErrorResponse) {
      this.snackBar.open(error.error, 'OK');
    }
  }
}
