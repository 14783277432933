import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Banklink, BanklinkResponse } from '@shared/models';
import { IState } from '.';
import { PaymentActions, PaymentActionTypes } from '../actions/payment.actions';

export interface IPaymentState extends EntityState<Banklink> {
  order?: BanklinkResponse['order'];
  loading: boolean;
}

export const adapter = createEntityAdapter<Banklink>({
  selectId: (banklink) => banklink.name,
});

export const initialState: IPaymentState = adapter.getInitialState({
  order: undefined,
  loading: false,
});

export function paymentReducer(
  state = initialState,
  action: PaymentActions
): IPaymentState {
  switch (action.type) {
    case PaymentActionTypes.LOAD_PAYMENTS: {
      return { ...state, loading: true };
    }
    case PaymentActionTypes.LOAD_PAYMENTS_SUCCESS: {
      const order = action.payload.order || {};
      // Save banklinks in the correct order to avoid sorting later
      console.log(action.payload.values);
      const banklinks = action.payload.values
        .map((link) => ({ ...link, order: order[link.name] || Infinity }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.order - b.order);
      return { ...adapter.addMany(banklinks, state), order, loading: false };
    }
    case PaymentActionTypes.LOAD_PAYMENTS_FAIL: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
}

export const selectState = (state: IState) => state.payment;

const { selectEntities } = adapter.getSelectors();

export const selectBanklinkEntities = createSelector(
  selectState,
  selectEntities
);
