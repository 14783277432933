import { Pipe, PipeTransform } from "@angular/core";

/*
 * Convert weight in gramms into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   item.weight | weight:precision
 * Example:
 *   {{ 1000 |  weight}}
 *   formats to: 1 kg
 */

@Pipe({ name: "weight" })
export class WeightPipe implements PipeTransform {
  private units = ["g", "kg"];

  transform(gramms: number = 0, precision: number = 0): string {
    if (isNaN(parseFloat(String(gramms))) || !isFinite(gramms)) return "?";

    let unit = 0;

    while (gramms >= 1000) {
      gramms /= 1000;
      unit++;
    }

    return gramms.toFixed(+precision) + " " + this.units[unit];
  }
}
