import { Component, Input, OnInit } from '@angular/core';
import { CartProduct, CartService } from '@shared/cart.service';
import { IProduct } from '@shared/product.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mp-item-preview',
  templateUrl: './item-preview.component.html',
  styleUrls: ['./item-preview.component.scss'],
})
export class ItemPreviewComponent implements OnInit {
  @Input() item!: IProduct;

  cartItem!: Observable<CartProduct>;

  constructor(private cartService: CartService) {}

  ngOnInit() {
    this.cartItem = this.cartService.cart.pipe(
      map((cart) => {
        const cartItem: CartProduct = {
          ...this.item,
          count: cart?.items?.[this.item.id as string] ?? 0,
        };
        return cartItem;
      })
    );
  }
}
