import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IProduct, ProductService } from '../../shared/product.service';

@Component({
  selector: 'mp-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent implements OnInit {
  items!: Observable<IProduct[] | undefined>;

  constructor(private itemService: ProductService) {}

  ngOnInit() {
    this.items = this.itemService.inStock;
    this.items.subscribe((data) => console.log(data));
  }
}
