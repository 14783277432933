import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { AuthService, User, UserRoles } from '../../auth.service';
import { CartProduct, CartService } from '../../cart.service';
import { ToolbarService } from '../../toolbar/toolbar.service';

@Component({
  selector: 'mp-shop-layout',
  templateUrl: './shop-layout.component.html',
  styleUrls: ['./shop-layout.component.scss'],
})
export class ShopLayoutComponent implements OnInit, OnDestroy {
  title = 'Meepood - avaleht';
  cartItems!: Observable<CartProduct[] | undefined | null>;
  user!: Observable<User | null | undefined>;
  roles!: Observable<UserRoles | null>;

  @HostBinding('class.transparent-toolbar') transparentToolbar!: boolean;
  private unsubscribe = new Subject();
  constructor(
    private auth: AuthService,
    private cartService: CartService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.toolbarService
      .getFixedChanges()
      .pipe(delay(0), takeUntil(this.unsubscribe))
      .subscribe((fixed) => (this.transparentToolbar = fixed));
    this.cartItems = this.cartService.cartItems;
    this.user = this.auth.user;
    this.roles = this.auth.roles;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    delete (this as any).unsubscribe;
  }
}
