import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(array: any | any[], separator: string = ',', lastSeparator?: string): any {
    console.log(array, separator, lastSeparator);
    if (!Array.isArray(array)) return array;
    if (lastSeparator === undefined) {
      return array.join(separator);
    } else {
      return array
        .slice(0, -1)
        .join(separator)
        .concat(array.length > 1 ? lastSeparator : '', ...array.slice(-1));
    }
  }
}
