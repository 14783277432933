import { Action } from '@ngrx/store';
import { BanklinkResponse } from '@shared/models';

export enum PaymentActionTypes {
  LOAD_PAYMENTS = '[Payment] Load Payments',
  LOAD_PAYMENTS_SUCCESS = '[Payment] Load Payments Success',
  LOAD_PAYMENTS_FAIL = '[Payment] Load Payments Fail',
}

export class LoadPayments implements Action {
  readonly type = PaymentActionTypes.LOAD_PAYMENTS;
}

export class LoadPaymentsSuccess implements Action {
  readonly type = PaymentActionTypes.LOAD_PAYMENTS_SUCCESS;
  constructor(public payload: BanklinkResponse) {}
}

export class LoadPaymentsFail implements Action {
  readonly type = PaymentActionTypes.LOAD_PAYMENTS_FAIL;
  constructor(public payload: Error) {}
}

export type PaymentActions =
  | LoadPayments
  | LoadPaymentsSuccess
  | LoadPaymentsFail;
