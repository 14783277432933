import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToolbarService {
  private fixedSubject = new BehaviorSubject<boolean>(false);
  constructor() {}

  setFixed(fixed: boolean) {
    this.fixedSubject.next(fixed);
  }
  getFixedChanges() {
    return this.fixedSubject.asObservable();
  }
}
