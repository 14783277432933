<div class="cart__container" *ngIf="cartItems">
  <div role="list">
    <div style="display: flex; flex: 1; flex-direction: column" *ngFor="let cartItem of cartItems" role="listitem">
      <mp-cart-item [cartItem]="cartItem" [static]="static" (removeItem)="removeItem.emit($event)"></mp-cart-item>
    </div>
  </div>
  <div class="cart__totals">
    <h4>Tooted kokku: {{ cartCost?.items ?? 0 | currency }}</h4>
    <h4>
      Kohaletoimetamine:
      <span *ngIf="cartCost?.transport !== undefined; else transportCostUndefined">{{
        cartCost?.transport | currency
      }}</span>
      <ng-template #transportCostUndefined>
        <span class="mat-hint">selgub tarnija valimisel</span>
      </ng-template>
    </h4>

    <h3>Kõik kokku: {{ cartCost?.total || 0 | currency }}</h3>
  </div>
</div>
