<div class="home__banner">
  <picture>
    <source
      media="(max-width: {{ size.small }})"
      srcset="https://storage.googleapis.com/ee-meepood-staging.appspot.com/image/s/cover_mobile.jpg"
    />
    <source media="(max-width: {{ size.medium }})" srcset="https://storage.googleapis.com/ee-meepood-staging.appspot.com/image/m/cover.jpg" />
    <source media="(max-width: {{ size.large }})" srcset="https://storage.googleapis.com/ee-meepood-staging.appspot.com/image/l/cover.jpg" />
    <source media="(min-width: {{ size.large }})" srcset="https://storage.googleapis.com/ee-meepood-staging.appspot.com/image/xl/cover.jpg" />
    <img
      class="home__cover"
      src="https://storage.googleapis.com/ee-meepood-staging.appspot.com/image/s/cover_mobile.jpg"
      alt="Niidutaimed"
    />
  </picture>
</div>
<div class="home__reasons"></div>
<mp-item-list class="home__item-list"></mp-item-list>
