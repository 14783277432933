import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Cart } from './cart.service';
import { DialogService } from './dialog.service';
import { Timestamp } from './models';
import { captureException } from './sentry';
import { takeValue } from './util';

export const FIREBASE_ACCOUNT_IN_USE = 'auth/credential-already-in-use';
export const FIREBASE_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL = 'auth/account-exists-with-different-credential';
export const FIREBASE_EMAIL_IN_USE = 'auth/email-already-in-use';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface UserProfile {
  fullName?: string;
  photoURL?: string;
  phoneNumber?: string;
  email?: string;
  providerDataUpdated?: Date;
}

export interface User extends UserProfile {
  uid: string;
  isAnonymous: boolean;
}

export interface UserResponse extends Omit<User, 'providerDataUpdated'> {
  providerDataUpdated?: Timestamp;
}

export interface UserProfileResponse extends Omit<UserProfile, 'providerDataUpdated'> {
  providerDataUpdated: Timestamp;
}

export interface UserRoles {
  admin?: boolean;
}

/**
 * fireAuth.user can be either null (no data in browser cache), Anonymous or regular user
 */

@Injectable()
export class AuthService {
  private userSubject = new BehaviorSubject<User | null | undefined>(undefined);
  private mergingSubject = new BehaviorSubject<boolean>(false);
  public user = this.userSubject.asObservable();
  public uid = this.fireAuth.user.pipe(map((user) => (user ? user.uid : null)));
  public roles: Observable<UserRoles | null>;
  public userProfile$: Observable<User | null>;
  public merging$ = this.mergingSubject.asObservable();

  get currentUser() {
    return takeValue(this.user);
  }

  constructor(
    private fireAuth: AngularFireAuth,
    private db: AngularFirestore,
    public dialog: DialogService,
    private router: Router
  ) {
    this.user.subscribe((user) => console.log('main-user', user));
    this.fireAuth.user.subscribe((user) => console.log('raw-user', user));

    this.userProfile$ = this.fireAuth.user.pipe(
      switchMap((fireUser) => {
        if (!fireUser) return of(null);
        const { isAnonymous, uid } = fireUser;
        if (isAnonymous) return of(this.userFactory({ uid } as UserResponse));
        return this.db
          .doc<UserProfileResponse>('users/' + uid)
          .valueChanges()
          .pipe(
            tap((data) => this.initEmptyProfileHandler(data as UserProfileResponse)),
            map((data) => this.userFactory({ ...data, uid, isAnonymous }))
          );
      })
    );
    this.userProfile$.subscribe((user) => this.userSubject.next(user));

    this.roles = this.fireAuth.user.pipe(
      switchMap((fireUser) => {
        if (!fireUser) return of(null);
        return from(fireUser.getIdTokenResult()).pipe(
          map((token) => {
            const { admin = false } = token.claims;
            return { admin };
          })
        );
      })
    );

    this.initRedirectHandler();
    this.initNewUserAuthHandler();
  }

  updateUser(user: User) {
    const { fullName, phoneNumber, email, photoURL } = user;
    return this.db.doc('users/' + user.uid).set({ fullName, phoneNumber, email, photoURL }, { merge: true });
  }

  initEmptyProfileHandler(userProfile: UserProfileResponse) {
    if (!userProfile) return;
    if (!userProfile.providerDataUpdated) {
      this.fireAuth.user.pipe(take(1)).subscribe(async (fireUser) => {
        if (!fireUser || fireUser.isAnonymous) return;
        const providerData = fireUser.providerData[0] as firebase.UserInfo;
        if (!providerData) return;

        await this.db.doc('users/' + fireUser.uid).set({
          fullName: providerData.displayName,
          phoneNumber: providerData.phoneNumber,
          photoURL: providerData.photoURL,
          email: providerData.email,
          providerDataUpdated: new Date(),
        } as UserProfile);
        console.log('profile initialized');
      });
    }
  }

  initNewUserAuthHandler() {
    this.user.pipe(filter((user) => user === null)).subscribe((user) => {
      console.log('anonymous login', user);
      this.loginAnonymously();
    });
  }

  getIdToken() {
    return this.fireAuth.currentUser.then((currentUser) => currentUser?.getIdToken());
  }

  async initRedirectHandler() {
    // Need to use try catch, because promise.catch breaks change detection somehow
    try {
      console.log('redirect');
      const result = await this.fireAuth.getRedirectResult();
      console.log('redirect', result);
      const user = result.user;
      const providerData = user?.providerData?.[0];
      if (!providerData || !user) return;
      return user.updateProfile({
        displayName: providerData.displayName,
        photoURL: providerData.photoURL,
      });
    } catch (error) {
      console.log('redirect error', error);
      switch (error.code) {
        case FIREBASE_ACCOUNT_IN_USE:
          return this.mergeAccounts(error.credential).catch((error) => {
            console.log(error);
          });
        case FIREBASE_EMAIL_IN_USE:
          this.dialog.openInfo(
            'Sisse logimine ebaõnnestus',
            `${error.credential.signInMethod === 'facebook.com' ? `Facebook'iga` : `Google'iga`}
            sisse logimine ebaõnnestus, sest email ${
              error.email
            } on juba seotud teise kontoga. Proovi uuesti sisse logida vajutades
            ${error.credential.signInMethod === 'facebook.com' ? 'Google' : 'Facebook'} nupule.`
          );
          break;
        default:
          throw error;
      }
    }
  }

  loginAnonymously() {
    this.fireAuth.signInAnonymously().catch(function (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  }

  loginGoogle() {
    return this.login(new firebase.auth.GoogleAuthProvider());
  }

  loginFacebook() {
    return this.login(new firebase.auth.FacebookAuthProvider());
  }

  async login(provider: firebase.auth.GoogleAuthProvider | firebase.auth.FacebookAuthProvider) {
    const fireUser = await this.fireAuth.currentUser;
    if (fireUser && fireUser.isAnonymous) {
      return fireUser.linkWithRedirect(provider).catch((error) => {
        if (error.code === FIREBASE_ACCOUNT_IN_USE) {
          return this.mergeAccounts(error.credential);
        }
        throw error;
      });
    } else {
      return this.fireAuth.signInWithRedirect(provider);
    }
  }

  async mergeAccounts(credential: any) {
    console.log('merge users');
    this.mergingSubject.next(true);
    const currentFireUser = await this.fireAuth.currentUser;

    // merge only anonymous user data for now
    if (!currentFireUser || !currentFireUser.isAnonymous) return Promise.resolve();

    const oldCart = await this.db.doc<Cart>(`carts/${currentFireUser.uid}`).snapshotChanges().pipe(take(1)).toPromise();

    const result = await this.fireAuth.signInAndRetrieveDataWithCredential(credential);

    if (!result?.user?.uid) {
      captureException(new Error('Merging anonymous account failed'));
      return Promise.resolve();
    }

    const doc = this.db.doc(`carts/${result.user.uid}`);
    const newCart = await doc.snapshotChanges().pipe(take(1)).toPromise();
    const oldCartData = oldCart.payload.data();
    const newCartDate = newCart.payload.data();

    console.log('old cart data', oldCartData, 'new cart data', newCartDate);
    //! TODO: set cart only if new cart (i.e. logged in users cart) is empty
    if (oldCartData) await doc.set(oldCart.payload.data());

    await currentFireUser.delete();
    this.mergingSubject.next(false);
  }

  userFactory(user: UserResponse): User {
    const defaultValues: Partial<User> = {
      fullName: undefined,
      phoneNumber: undefined,
      photoURL: undefined,
      email: undefined,
      isAnonymous: true,
    };
    const providerDataUpdated = user?.providerDataUpdated?.toDate();

    return { ...defaultValues, ...user, providerDataUpdated };
  }

  logout() {
    this.fireAuth.signOut();
    this.router.navigate(['/avaleht']);
  }
}
