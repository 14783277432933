import { Component, OnInit } from '@angular/core';
import { LegalDocument, LegalService } from '../legal.service';

@Component({
  selector: 'mp-terms',
  template: `
    <div
      *ngIf="data"
      style="max-width: 1000px; margin: 1rem auto; padding: 1rem;"
    >
      <div [innerHtml]="data.content"></div>
      <div style="margin-top: 4rem;">
        Viimati muudetud: {{ data.modified.toDate() | date: 'yyyy-MM-dd' }}
      </div>
    </div>
  `,
})
export class TermsComponent implements OnInit {
  data!: LegalDocument | undefined;
  constructor(private legalService: LegalService) {}

  async ngOnInit() {
    const documents = await this.legalService.getDocuments().toPromise();
    this.data = documents.find((document) => document.id === 'terms');
  }
}
